// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import portalService from '@/api/portalService'
import { getPagedQueryString } from '@/utils/getPagedQueryString'
import { IPageInfo } from '@/_typings/interfaces/paging'
import handleError from '@/store/handleError'

const state= {
}

const getters = {
}

const mutations = {
}

const actions = {
  addSalesOrder({ getters }, { salesOrder }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/sales-orders`, salesOrder)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteSalesOrder({ getters }, { salesOrderId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/sales-orders/${salesOrderId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteSalesOrders({ getters }, { salesOrderIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/sales-orders/delete-many`, salesOrderIds)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchSalesOrder({ getters }, { salesOrderId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/sales-orders/${salesOrderId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchSalesOrderLines({ getters }, { salesOrderId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/sales-orders/${salesOrderId}/lines?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchSalesOrders({ getters }, { pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/sales-orders?${query}`)
        .then(({ data }) => {
          resolve({ ...data, items: data.items.map(item => { return { ...item, accountName: item.account ? item.account.name : null, ownerName: item.owner ? item.owner.fullName : null } }) })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchSalesOrderStatuses({ getters }, { salesOrderId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/sales-orders/${salesOrderId}/statuses`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateSalesOrder({ getters }, { salesOrder }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/sales-orders/${salesOrder.id}`, salesOrder)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateSalesOrderStatus({ getters }, { salesOrderId, salesOrderStatus }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/sales-orders/${salesOrderId}/actions/${salesOrderStatus.action}`, { reason: salesOrderStatus.reason })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}