// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { Nullable } from '@/_typings/types/nullable'
import JQuery from 'jquery'

export default defineComponent({
  name: 'server-error',
  data() {
    return {
      errorMessage: null as Nullable<string>
    }
  },
  mounted() {
    JQuery('#server-error').on('show.bs.modal', this.showModal)
    JQuery('#server-error').on('hide.bs.modal', this.hideModal)
  },
  methods: {
    hideModal() {
      this.errorMessage = null
    },
    showModal() {
      this.errorMessage = JQuery('#server-error').data('bs-error-message')
    }
  }
})