// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { IBreadcrumb } from '@/_typings/interfaces/breadcrumb'

export default defineComponent({
  name: 'breadcrumb',
  created() {
    // @ts-ignore
    if (this.$route && this.$route.meta && this.$route.meta.breadcrumbs) {
      // @ts-ignore
      this.$route.meta.breadcrumbs.forEach(breadcrumb => {
        if (breadcrumb.paramId) {
          const entityName = breadcrumb.name === 'opportunity-quote' ? 'quotes' : breadcrumb.name === 'opportunity' ? 'opportunities' : breadcrumb.name + 's'
          const entityId = this.$route.params[breadcrumb.paramId]
          this.fetchEntityName({ entityName: entityName, entityId: entityId }).then(data => {
            this.setBreadcrumbText({ id: entityId, text: data })
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      breadcrumbText: 'getBreadcrumbText'
    }),
    breadcrumbs() {
      // @ts-ignore
      return this.$route && this.$route.meta && this.$route.meta.breadcrumbs
        // @ts-ignore
        ? this.$route.meta.breadcrumbs.map(breadcrumb => {
            return {
              path: this.$router.resolve({ name: breadcrumb.name, params: this.$route.params }).href, // query: this.$route.query
              name: breadcrumb.name,
              text: this.getBreadcrumbText(breadcrumb)
            }
          })
        : []
    },
    dashboardUri() {
      return this.$router.resolve({ name: 'dashboard', params: this.$route.params }).href
    }
  },
  methods: {
    ...mapMutations([
      'setBreadcrumbText'
    ]),
    ...mapActions([
      'fetchEntityName'
    ]),
    getBreadcrumbText(breadcrumb) {
      return breadcrumb.locizeKey
        ? this.$t(breadcrumb.locizeKey)
        : this.breadcrumbText(this.$route.params[breadcrumb.paramId])
    },
    redirect(path) {
      this.$router.replace(path)
    }
  }
})