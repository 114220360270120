import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import locizer from 'locizer'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app')

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $t: locizer;
  }
}
