// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import JQuery from 'jquery'

export default defineComponent({
  name: 'navigation-bar',
  data() {
    return {
      activeNavigation: null,
      isOpen: false
    }
  },
  methods: {
    navigate(routeName) {
      this.$router.push({ name: routeName })
      this.activeNavigation = routeName
      JQuery('[data-toggle="tooltip"]').tooltip('hide')
    }
  }
})
