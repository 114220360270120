// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { SIGNALR_MESSAGE_STATE_GOOD, SIGNALR_MESSAGE_STATE_ERROR } from '@/store/signalRMessageStates'
import { AlertType } from '@/_typings/enums/alertType'
import downloadFile from '@/utils/downloadFile'

export default defineComponent({
  computed: {
    ...mapGetters({
      signalRMessageState: 'getSignalRMessageState',
      task: 'getTask'
    })
  },
  methods: {
    ...mapMutations([
      'setAlert'
    ]),
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
         var ascii = binaryString.charCodeAt(i)
         bytes[i] = ascii
      }
      return bytes
    }
  },
  watch: {
    signalRMessageState() {
      switch (this.signalRMessageState.taskType) {
        case 'GenerateDocument':
          if (this.signalRMessageState.status === SIGNALR_MESSAGE_STATE_GOOD)
            this.setAlert({ type: AlertType.Success, message: this.$t('reseller_document-generation-success_message'), timeout: 5000 })
          else if (this.signalRMessageState.status === SIGNALR_MESSAGE_STATE_ERROR)
            this.setAlert({ type: AlertType.Danger, message: this.$t('reseller_document-generation-error_message'), timeout: 5000 })
          break
        default:
          break
      }
    },
    task() {
      switch (this.task.taskType) {
        case 'GenerateDocument':
          const documentName = this.task?.properties?.response?.name || 'Document'
          const documents = this.task?.properties?.response?.documents
          let documentCount = 0
          if (documents) {
            for (let document of documents) {
              const arrayBuffer = this.base64ToArrayBuffer(document.document)
              const documentBlob = new Blob([arrayBuffer], { type: document.format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
              downloadFile(documentBlob, documentName.replace(/[|&;$%@"<>()+,.]/g, '') + (documentCount > 0 ? '-' + documentCount.toString() : ''))
              documentCount++
            }  
          }
          break
        default:
          break
      }
    }
  }
})
