import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "breadcrumb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "breadcrumb-link router-link-active",
      to: _ctx.dashboardUri,
      onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.redirect('/')), ["prevent"]), ["space"]))
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('reseller_home_breadcrumb')), 1)
      ]),
      _: 1
    }, 8, ["to"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: breadcrumb.name,
        class: "breadcrumb-link",
        tag: "span",
        to: breadcrumb.path,
        onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.redirect(breadcrumb.path)), ["prevent"]), ["space"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(breadcrumb.text), 1)
        ]),
        _: 2
      }, 1032, ["to", "onKeydown"]))
    }), 128))
  ]))
}