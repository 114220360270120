// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
import { Nullable } from '@/_typings/types/nullable'
/* istanbul ignore file */

const USA_ENGLISH = 'en-US'
const FRENCH = 'fr'

function Localization() {
  return {
    formatDate: (date) => {
      return date ? new Date(date).toLocaleDateString(getLocale(), { year: 'numeric', month: '2-digit', day: '2-digit' }) : null
    },
    formatDateTime: (date) => {
      return date
        ? new Date(date).toLocaleDateString(getLocale(), { year: 'numeric', month: '2-digit', day: '2-digit' }) +
          '\xa0 - \xa0' +
          new Date(date).toLocaleTimeString(getLocale())
        : null
    },
    formatDateUTC: (date) => {
      if (!date) return null
      const fullDate = new Date(date)
      return {
        [USA_ENGLISH]: `${fixDigit(fullDate.getUTCMonth() + 1)}/${fixDigit(fullDate.getUTCDate())}/${fullDate.getUTCFullYear()}`,
        [FRENCH]: `${fixDigit(fullDate.getUTCDate())}/${fixDigit(fullDate.getUTCMonth() + 1)}/${fullDate.getUTCFullYear()}`
      }[getLocale()]
    },
    formatDateTimeUTC: (date) => {
      if (!date) return null
      const fullDate = new Date(date)
      return {
        [USA_ENGLISH]: `${fixDigit(fullDate.getUTCMonth() + 1)}/${fixDigit(fullDate.getUTCDate())}/${fullDate.getUTCFullYear()}\xa0${new Date(date).toLocaleTimeString(getLocale())}`,
        [FRENCH]: `${fixDigit(fullDate.getUTCDate())}/${fixDigit(fullDate.getUTCMonth() + 1)}/${fullDate.getUTCFullYear()}\xa0${new Date(date).toLocaleTimeString(getLocale())}`
      }[getLocale()]
    },
    formatCurrency: (value) => {
      return value || value === 0 ? Number(value).toLocaleString(getLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null
    },
    formatNumber: (value: any, decimalDigits: any = null) => {
      // eslint-disable-next-line
      return value || value === 0 ? Number(value).toLocaleString(getLocale(), { minimumFractionDigits: decimalDigits ? decimalDigits : 0, maximumFractionDigits: decimalDigits || decimalDigits === 0 ? decimalDigits : 15 }) : null
    },
    getUniversalNumber(value: Nullable<string>) {
      const thousandsSeparator = Number(1000).toLocaleString(getLocale()).replace(/[\u0080-\uffff]/g, ' ').charAt(1)
      const decimalSeparator = Number(1.1).toLocaleString(getLocale()).charAt(1)
      const universalNumber = value || value === '0'
        ? value.toString().trim()
          .replace(/[\u0080-\uffff]/g, '') // remove non-ASCII characters
          .replace(new RegExp(thousandsSeparator, 'g'), '') // remove thousands separator
          .replace(decimalSeparator, '.') // replace decimal separator
        : null
      return universalNumber !== null && !isNaN(universalNumber as any) ? parseFloat(universalNumber).toString() : universalNumber
    }
  }
}

function fixDigit(value) {
  return value.toString().length === 1 ? '0' + value : value
}

function getLocale() {
  return USA_ENGLISH // Temp until we support multi-language
  // const locale = window.localStorage.getItem('languageCode')
  // return locale ? locale : USA_ENGLISH
}

export default Localization()