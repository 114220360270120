// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
// @ts-nocheck
export default function downloadFile(blob, fileName) {
  if (window.navigator.msSaveBlob) { // for IE 11
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    let link = document.createElement('a')
    link.href = window.URL && window.URL.createObjectURL ? window.URL.createObjectURL(blob) : null
    link.download = fileName
    document.body.appendChild(link)
    link.click()
  }
}
