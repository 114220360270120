import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "app-container" }
const _hoisted_3 = {
  key: 0,
  class: "app-header"
}
const _hoisted_4 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_bar = _resolveComponent("navigation-bar")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_alert = _resolveComponent("alert")!
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_confirmation = _resolveComponent("confirmation")!
  const _component_server_error = _resolveComponent("server-error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isReadyI18n)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$route.name != 'login' && _ctx.$route.name !== 'dashboard')
            ? (_openBlock(), _createBlock(_component_navigation_bar, {
                key: 0,
                routeName: _ctx.$route.name
              }, null, 8, ["routeName"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.$route.name !== 'login')
              ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
                  (_ctx.user)
                    ? (_openBlock(), _createBlock(_component_main_header, {
                        key: 0,
                        onLogout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logoutUser()))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.alert)
                    ? (_openBlock(), _createBlock(_component_alert, {
                        key: 1,
                        type: _ctx.alert.type,
                        title: _ctx.alert.title,
                        message: _ctx.alert.message,
                        timeout: _ctx.alert.timeout,
                        onClose: _ctx.hideAlert
                      }, null, 8, ["type", "title", "message", "timeout", "onClose"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("main", _hoisted_4, [
              (_ctx.$route.name !== 'login' && _ctx.$route.name !== 'dashboard')
                ? (_openBlock(), _createBlock(_component_breadcrumb, {
                    key: _ctx.$route.path
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component, route }) => [
                  _createVNode(_Transition, { name: "fade" }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: route.path
                      }))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_confirmation),
            _createVNode(_component_server_error)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}