// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import portalService from '@/api/portalService'
import { getPagedQueryString } from '@/utils/getPagedQueryString'
import { IPageInfo } from '@/_typings/interfaces/paging'
import handleError from '@/store/handleError'

const state= {
}

const getters = {
}

const mutations = {
}

const actions = {
  addAccount({ getters }, { account }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/accounts`, account)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addAccountAddress({ getters }, { accountId, address }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses`, address)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addAccountContact({ getters }, { accountId, contact }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/accounts/${accountId}/contacts`, contact)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteAccount({ getters }, { accountId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/accounts/${accountId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteAccounts({ getters }, { accountIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/accounts/delete-many`, accountIds)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteAccountAddress({ getters }, { accountId, addressId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses/${addressId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteAccountContact({ getters }, { accountId, contactId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/accounts/${accountId}/contacts/${contactId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAccount({ getters }, { accountId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/accounts/${accountId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAccountAddress({ getters }, { accountId, addressId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses/${addressId}`)
        .then(({ data }) => {
          resolve({ ...data.address, isBilling: data.isBilling, isShipping: data.isShipping })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAccountAddresses({ getters }, { accountId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAccountContact({ getters }, { accountId, contactId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/accounts/${accountId}/contacts/${contactId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAccountContacts({ getters }, { accountId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/accounts/${accountId}/contacts?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAccounts({ getters }, { pageInfo = {}, opportunityId = null, quoteId = null, contactId = null, activeOnly = false }) {
    let query = getPagedQueryString(pageInfo as IPageInfo)
    if (opportunityId) query = query + (query.length ? '&' : '') + 'opportunityId=' + opportunityId
    else if (quoteId) query = query + (query.length ? '&' : '') + 'quoteId=' + quoteId
    else if (contactId) query = query + (query.length ? '&' : '') + 'contactId=' + contactId
    else if (activeOnly) query = query + (query.length ? '&' : '') + 'activeOnly=' + activeOnly.toString()
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/accounts?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateAccount({ getters }, { account }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/accounts/${account.id}`, account)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateAccountAddress({ getters }, { accountId, address }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses/${address.id}`, address)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateAccountContact({ getters }, { accountId, contact }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/accounts/${accountId}/contacts/${contact.id}`, contact)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateAccountDefaultBillingAddress({ getters, commit }, { accountId, addressId, value }) {
    commit('setIsChangeProcessing', true)
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses/${addressId}/default-billing`, value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
        .finally(() => {
          commit('setIsChangeProcessing', false)
        })
    })
  },
  updateAccountDefaultShippingAddress({ getters, commit }, { accountId, addressId, value }) {
    commit('setIsChangeProcessing', true)
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/accounts/${accountId}/addresses/${addressId}/default-shipping`, value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
        .finally(() => {
          commit('setIsChangeProcessing', false)
        })
    })
  },
  updateAccountPrimaryContact({ getters, commit }, { accountId, contactId, value }) {
    commit('setIsChangeProcessing', true)
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/accounts/${accountId}/contacts/${contactId}/primary`, value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
        .finally(() => {
          commit('setIsChangeProcessing', false)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}