// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
export const DELETE = 'DELETE'
export const DELETE_MULTIPLE = 'DELETE_MULTIPLE'
export const UNSAVED_CHANGES = 'UNSAVED_CHANGES'

export default {
  DELETE,
  DELETE_MULTIPLE,
  UNSAVED_CHANGES
}