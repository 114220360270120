// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import portalService from '@/api/portalService'
import { getPagedQueryString } from '@/utils/getPagedQueryString'
import { IPageInfo } from '@/_typings/interfaces/paging'
import handleError from '@/store/handleError'

const state= {
}

const getters = {
}

const mutations = {
}

const actions = {
  addQuote({ getters }, { quote }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/quotes`, quote)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addQuoteCatalogItems({ getters }, { quoteId, catalogItems }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/catalog/items`, catalogItems)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  copyQuote({ getters }, { quoteId }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/actions/copy`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteQuote({ getters }, { quoteId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/quotes/${quoteId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteQuoteLine({ getters }, { quoteId, lineId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/lines/${lineId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteQuoteLines({ getters }, { quoteId, lineIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/lines/delete-many`, lineIds)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteQuotes({ getters }, { quoteIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/quotes/delete-many`, quoteIds)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchQuote({ getters }, { quoteId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/quotes/${quoteId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchQuoteCatalog({ getters }, { quoteId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/catalog?${query}`, [])
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchQuoteLines({ getters }, { quoteId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/lines?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchQuotes({ getters }, { pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/quotes?${query}`)
        .then(({ data }) => {
          resolve({ ...data, items: data.items.map(item => { return { ...item, accountName: item.account ? item.account.name : null, ownerName: item.owner ? item.owner.fullName : null } }) })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchQuoteStatuses({ getters }, { quoteId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/statuses`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  reviseQuote({ getters }, { quoteId }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/actions/revise`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateQuote({ getters }, { quote }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/quotes/${quote.id}`, quote)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateQuoteStatus({ getters }, { quoteId, quoteStatus }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/quotes/${quoteId}/actions/${quoteStatus.action}`, { reason: quoteStatus.reason })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}