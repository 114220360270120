// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { SIGNALR_CONNECTION_STATE_CLOSED } from '@/store/signalRConnectionStates'
import { SIGNALR_MESSAGE_STATE_GOOD, SIGNALR_MESSAGE_STATE_ERROR } from '@/store/signalRMessageStates'
import signalRConnection from '@/store/signalRConnection'
import store from '@/store'

const state: any = {
  signalRConnectionState: SIGNALR_CONNECTION_STATE_CLOSED,
  signalRMessageState: null,
  task: {} as any
}

const getters = {
  getSignalRConnectionState(state) {
    return state.signalRConnectionState
  },
  getSignalRMessageState(state) {
    return state.signalRMessageState
  },
  getTask(state) {
    return state.task
  }
}

const mutations = {
  setSignalRConnectionState(state, connectionState) {
    state.signalRConnectionState = connectionState
  },
  setSignalRMessageState(state, messageState) {
    state.signalRMessageState = messageState
  },
  setTask(state, task) {
    state.task = task
  }
}

const actions = {
  generateDocument({ getters }, { quoteId }) {
    return signalRConnection.taskHub
      .invoke('CreateTask',
        getters.getUserTenantId,
        {
          taskType: 'GenerateDocument',
          properties: {
            request: {
              tenantId: getters.getUserTenantId,
              quoteId: quoteId
            }
          }
        }
      )
      .catch(err => console.error(err.toString()))
  }
}

const webSocketResponseEvents = () => {
  if (!signalRConnection) return
  signalRConnection.taskHub.on('TaskCompleted', data => {
    store.commit('setSignalRMessageState', { requestName: 'CreateTask', status: SIGNALR_MESSAGE_STATE_GOOD, taskType: data.taskType })
    store.commit('setTask', data)
  })
  signalRConnection.taskHub.on('TaskFailed', data => {
    store.commit('setSignalRMessageState', { requestName: 'CreateTask', status: SIGNALR_MESSAGE_STATE_ERROR, taskType: data.taskType })
  })
}
webSocketResponseEvents()

export default {
  state,
  getters,
  mutations,
  actions
}