import { createI18n } from 'vue-i18n'
import locizer from 'locizer'
import store from '@/store'

locizer
  .init({
    projectId: '7d7cda9c-bf53-40cd-8d77-e376e91e2cc5',
    fallbackLng: 'en-US',
    referenceLng: 'en-US',
    version: 'production',
    loadIfTranslatedOver: 0.1
  })
  .loadAll('translation', (err, messages) => {
    if (err) throw(err)
    Object.keys(messages).forEach((l) => {
      i18n.global.setLocaleMessage(l, messages[l])
    })
    store.commit('setIsReadyI18n', true)
  })

// locizer.init({
//   projectId: '7d7cda9c-bf53-40cd-8d77-e376e91e2cc5',
//   fallbackLng: 'en-US',
//   referenceLng: 'en-US',
//   version: 'production',
//   loadIfTranslatedOver: 0.1
// })

export const i18n = createI18n({
  locale: locizer.lng,
  fallbackLocale: 'en-US'
})

// called from within setup hook in App.vue
// export const loadMessagesPromise = new Promise((resolve, reject) => {
//   locizer.loadAll('translation', (err, messages) => {
//     if (err) return reject(err)
//     Object.keys(messages).forEach((l) => {
//       i18n.global.setLocaleMessage(l, messages[l])
//     })
//     resolve(messages)
//   })
// })
