// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED

const devEnvID = 'NV3S47VCBMyEx2GBUgero4'
const uatEnvID = 'kZCQPrFPy9NzDhUdFuJokP'
const prodEnvID = 'nUrwHvtuUtKL8Pap5GTtLA'
const preProdEnvID = 'RUKVHZojSXWzTiK8VBaFbr'
const demoEnvID = 'afry47aPb4UrEPVzymHVQE'

export const getFlagsmithEnvID = (hostname, appName) => {
  if (hostname === 'mycincom.cincom.com' && appName === 'cca') return prodEnvID
  const hostnameToEnvID = getHostnameToEnvID(appName)
  return hostnameToEnvID[hostname] || devEnvID
}

function getHostnameToEnvID(appName) {
  const hostNames = getHostNames(appName)
  return {
    [hostNames.localURL]: devEnvID,
    [hostNames.devURL]: devEnvID,
    [hostNames.devBlueGreenURL]: devEnvID,
    [hostNames.uatBlueGreenURL]: uatEnvID,
    [hostNames.uatURL]: uatEnvID,
    [hostNames.crmURL]: devEnvID,
    [hostNames.demoProdURL]: demoEnvID,
    [hostNames.internalDemoProdURL]: demoEnvID,
    [hostNames.internalProdURL]: prodEnvID,
    [hostNames.internalCentralUSProdURL]: prodEnvID,
    [hostNames.preProdURL]: preProdEnvID,
    [hostNames.prodURL]: prodEnvID
  }
}

function getHostNames(appName) {
  return {
    devURL: `${appName}.devkube.eastus2-dev.cincomcloud.com`,
    devBlueGreenURL: `${appName}-test.devkube.eastus2-dev.cincomcloud.com`,
    uatBlueGreenURL: `${appName}-test.qakube.eastus2-dev.cincomcloud.com`,
    uatURL: `${appName}.qakube.eastus2-dev.cincomcloud.com`,
    crmURL: `${appName}-crm.qakube.eastus2-dev.cincomcloud.com`,    
    internalProdURL: `${appName}.prodkube.prod.cincomcloud.com`,
    internalCentralUSProdURL: `${appName}.prodkube.centralus-prod.cincomcloud.com`,
    internalDemoProdURL: `${appName}.prodkube.prod.cincomcloud.com`,
    preProdURL: `${appName}.prodkube.prod.cincomcloud.com`,
    prodURL: `${appName}.cincomcpq.com`,
    demoProdURL: `${appName}.prodkube.cincomcpq.com`,
    localURL: 'localhost'
  }
}
