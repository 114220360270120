
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { getUserInfo, waitForAuthentication, logout } from '@/auth'
import { getFlagsmithEnvID } from '@/flagsmithCredentials'
// import { loadMessagesPromise } from './i18n'
import Alert from '@/components/controls/alert/index.vue'
import Breadcrumb from '@/components/misc/breadcrumb/index.vue'
import Confirmation from '@/components/modals/confirmation/index.vue'
import MainHeader from '@/components/misc/main-header/index.vue'
import NavigationBar from '@/components/misc/navigation-bar/index.vue'
import ServerError from '@/components/modals/server-error/index.vue'
import taskHubMixin from '@/mixins/taskHubMixin'
import signalRConnection from '@/store/signalRConnection'
import flagsmith from 'flagsmith'
import JQuery from 'jquery'

export default defineComponent({
  name: 'app',
  components: {
    Alert,
    Breadcrumb,
    Confirmation,
    MainHeader,
    NavigationBar,
    ServerError
  },
  mixins: [ taskHubMixin ],
  created() {
    flagsmith.init({
      environmentID: getFlagsmithEnvID(window.location.hostname, 'reseller'),
      onChange: (oldFlags, params) => { // Occurs whenever flags are changed
        this.setFeatureFlags({
          allFlags: flagsmith.getAllFlags(),
          params: params
        })
      }
    })
  },
  updated() {
    JQuery(function () {
      JQuery('[data-toggle="tooltip"]').tooltip({ placement: 'top', customClass: 'tooltip', trigger: 'hover', delay: { show: 800, hide: 100 } })
    })
  },
  // setup() {
  //   loadMessagesPromise
  //   return {}
  // },
  computed: {
    ...mapGetters({
      alert: 'getAlert',
      isReadyI18n: 'getIsReadyI18n',
      user: 'getUser'
    })
  },
  methods: {
    ...mapMutations([
      'setAlert',
      'setFeatureFlags',
      'setUser'
    ]),
    hideAlert() {
      this.setAlert(null)
    },
    getLoggedOnUser() {
      return new Promise(async (resolve, reject) => {
        waitForAuthentication().then(async () => {
          try {
            const user = await getUserInfo()
            this.setUser(user)
            resolve(user)
          } catch (e) {
            console.error(e)
            reject(e)
          }
        }).catch(() => {
          reject()
        })
      })
    },
    async logoutUser() {
      await logout(null)
    }
  },
  watch: {
    $route() {
      if (this.$route.name !== 'login') {
        this.getLoggedOnUser().then(() => {}).catch(() => {})
      }
    },
    alert(newValue) {
      if (newValue && newValue.timeout) {
        setTimeout(() => {
          this.hideAlert()
        }, Number(newValue.timeout))
      }
    },
    user(newValue, oldValue) {
      if (newValue && newValue.userid && (!oldValue || newValue.userid !== oldValue.userid)) {
        signalRConnection.start()
      }
    }
  }
})
