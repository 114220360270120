// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import axios from 'axios'
import { setupInterceptors } from '@/utils/httpInterceptors'

let httpClient = axios.create({
  baseURL: window.KUBE_SETTINGS.CPQPortalServiceURL,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json'}
})
setupInterceptors(httpClient)

export default httpClient
