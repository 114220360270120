// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { IAPIError } from '@/_typings/interfaces/api-error'
import JQuery from 'jquery'

export default (error: any) => {
  const errors: IAPIError[] = error && error.response && error.response.data ? error.response.data.errors : []
  const errorText: string = errors.length > 0 ? errors[0].message : 'Unknown error :('
  const correlationId: string = error && error.response && error.response && error.response.headers ? error.response.headers.correlationid : null
  JQuery('#server-error').data('bs-error-message', errorText)
  JQuery('#server-error').modal()
  console.error('error = ', errorText, ' correlationId = ', correlationId)
}