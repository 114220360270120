// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { IPageInfo } from '@/_typings/interfaces/paging'
import * as Query from '@/utils/query'

export function getPagedQueryString(pageInfo: IPageInfo): string {
  let queryObject = {} as any
  if (pageInfo && pageInfo.pageNumber) queryObject.PageNumber = pageInfo.pageNumber
  if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
  if (pageInfo && pageInfo.sortedBy) queryObject.SortBy = pageInfo.sortedBy
  if (pageInfo && pageInfo.sortDirection && pageInfo.sortDirection !== 'None') queryObject.SortDirection = pageInfo.sortDirection
  if (pageInfo && pageInfo.search) queryObject.searchText = pageInfo.search
  const query = Query.qs(queryObject)
  return query
}