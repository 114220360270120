// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
import portalService from '@/api/portalService'
import handleError from '@/store/handleError'

const state= {
}

const getters = {
}

const mutations = {
}

const actions = {
  fetchLogo({ getters }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/logo`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}