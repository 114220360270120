// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { OktaAuth, UserClaims } from '@okta/okta-auth-js'
import router from './router'
import axios from 'axios'
import store from '@/store'
import { createFlagsmithInstance } from 'flagsmith'
import { getFlagsmithEnvID } from './flagsmithCredentials'

declare global {
  interface Window {
    KUBE_SETTINGS: any;
  }
}

// let redirectUri = document.location.origin + '/implicit/callback'
// let redirectUri = document.location.origin + '/authorization-callback'
const redirectUris = [document.location.origin + '/implicit/callback', document.location.origin + '/authorization-callback']

let useOktaLogin = true // Set by setUseOktaLogin()
let useLoginLogging = true // Set by flagsmith.init(), used by loginLog()
let flagsLoaded = false

let receivedTokenError = false // Set by validateAccessAsync() and getReceivedTokenErrorAndReset()
let loggingOut = false // Set by logout()
let oktaAuthError = false // Set by login() and callback()
let oktaAuthSubscribed = false // Used by setTokens()

export const authConfig = {
  clientId: window.KUBE_SETTINGS.clientId,
  issuer: window.KUBE_SETTINGS.issuer,
  redirectUri: redirectUris[1], // Set by setRedirectUri() and initOkta()
  scopes: ['openid', 'profile', 'email'],
  pkce: true
}

const noValidateRouteNames = ['callback', 'login', 'logout', 'authorization-callback']

const flagsmithLogin = createFlagsmithInstance()
flagsmithLogin.identify('default_user')
flagsmithLogin.init({
  environmentID: getFlagsmithEnvID(
    window.location.hostname,
    window.location.hostname.split('.')[0].split('-')[0]
  ),
  cacheFlags: false,
  onChange: (oldFlags, params) => { // Occurs whenever flags are changed
    useLoginLogging = flagsmithLogin.hasFeature('login-logging')
    loginLogWriteOutputQueue()
    flagsLoaded = true
  }
})

let oktaAuth = new OktaAuth(authConfig)
oktaAuth.options.redirectUri = undefined

/** Extracts query variable from url */
function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

/** false if not user.email === loginHint */
function hintMatchesSessionUser(): Promise<any> {
  loginLog('hintMatchesSessionUser()')
  return new Promise(async (resolve, reject) => {
    const loginHint = getQueryVariable('login_hint')
    if (loginHint != null) {
      await getUserInfo()
        .then(user => {
          loginLog('user: ', user)
          loginLog('loginHint: ', loginHint)
          resolve(user.email === loginHint)
        })
        .catch(err => {
          console.error(err)
          loginLog('hintMatchesSessionUser() getUserInfo() catch() err.message = ' + err.message)
          reject(err)
        })
    }
    resolve(true)
  })
}

/** If not already then set http header Authorization */
async function setHttpBearerToken() {
  if (!axios.defaults.headers.common.Authorization) {
    const token = await getAccessToken()
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`
      loginLog('setHttpBearerToken() new Authorization =', axios.defaults.headers.common.Authorization)
    } else {
      loginLog('setHttpBearerToken() **** FAILURE ****, token =', token)
    }
  } else {
    loginLog('setHttpBearerToken() Authorization already set')
  }
}

/** Sets originalUri in session storage */
function setOriginalUri(originalUriValue: any): void {
  loginLog('setOriginalUri() originalUriValue=', originalUriValue)
  if (!originalUriValue || originalUriValue === 'null') {
    sessionStorage.setItem('originalUri', '')
    return
  }
  let inNoValidateRouteNames = false
  noValidateRouteNames.forEach(routeName => {
    if (originalUriValue.toLowerCase().includes('/' + routeName.toLowerCase())) {
      inNoValidateRouteNames = true
      loginLog('setOriginalUri() noValidateRouteName=', routeName)
    }
  })
  if (inNoValidateRouteNames) {
    sessionStorage.setItem('originalUri', '/')
    loginLog('setOriginalUri() inNoValidateRouteNames=true originalUriValue=/')
  } else {
    sessionStorage.setItem('originalUri', originalUriValue)
    loginLog('setOriginalUri() inNoValidateRouteNames=false originalUriValue=', originalUriValue)
  }
}

/** Checks for and goes to originalUri if not null */
export function checkForOriginalUri(next: Function | null): boolean {
  loginLog('checkForOriginalUri() next=', next)

  const originalUri = sessionStorage.getItem('originalUri')
  loginLog('checkForOriginalUri() originalUri=', originalUri)
  setOriginalUri(null)
  if (originalUri && originalUri !== 'null') {
    if (next) {
      loginLog('checkForOriginalUri() calling next()')
      next({ path: originalUri })
    } else {
      loginLog('checkForOriginalUri() goto originalUri')
      window.location.replace(originalUri)
    }
    return true
  } else {
    loginLog('checkForOriginalUri() returning without calling next()')
    return false
  }
}

/** Calls oktaAuth.authStateManager.subscribe() - prevents browser console message:
 *    [okta-auth-sdk] WARN: updateAuthState is an asynchronous method with no return,
 *    please subscribe to the latest authState update with authStateManager.subscribe(handler) method before calling updateAuthState.
 */
 async function oktaAuthSubscribe(): Promise<void> {
  if (oktaAuth && oktaAuth.authStateManager) {
    loginLog('oktaAuthSubscribe() subscribing to authStateManager')
    oktaAuth.authStateManager.subscribe(authState => {
      // handle latest authState
    })
    loginLog('oktaAuthSubscribe() updating authStateManager')
    await oktaAuth.authStateManager.updateAuthState()
    oktaAuthSubscribed = true
  }
}

/** Calls oktaAuth.isAuthenticated() and goes to login(), originalUri, or next() */
export async function validateAccessAsync(to: any, from: any, next: Function): Promise<void> {
  const validateAccessId = Math.floor(Math.random() * 10000)
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') to: name=' + to.name + ', path=' + to.path + ', fullpath=' + to.fullPath + ', login_hint=' + to.query.login_hint)
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') from: name=' + from.name + ', path=' + from.path + ', fullPath=' + from.fullPath + ', login_hint=' + from.query.login_hint)
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') redirectUri=' + getRedirectUri())

  // Check whether or not logging out is in progress.
  if (loggingOut) {
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling next() 1 loggingOut=', loggingOut)
    next()
    return
  }
 
  // Calling initOkta() just in case has never been called.
  initOkta(0)

  // Check whether or not the 'to' route is an authenticated route.
  if (noValidateRouteNames.includes(to.name)) {
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling next() 2 (noValidateRouteNames)')
    next()
    return
  }

  // Get the current session.
  let session
  try {
    session = await oktaAuth.session.get()
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') session id/status = ' + session.id + '/' + session.status + ', session object =', session)
  } catch (err) {
    receivedTokenError = true
    console.error(err)
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 3 (oktaAuth.session.get() catch() err.message = ' + (err as Error).message + ')')
    await logout(next)
    return 
  }

  // Seeing if user is already athenticated.
  let isAuthenticated = false
  try {
    isAuthenticated = await oktaAuth.isAuthenticated()
  } catch (err) {
    console.error(err)
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 4 (oktaAuth.isAuthenticated() catch() err.message = ' + (err as Error).message + ')')
    await logout(next)
    return 
  }

  if (!isAuthenticated) {
    if (!session || session.status !== 'ACTIVE') {
      loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling login() 5 (!isAuthenticated !ACTIVE)')
      login(next)
      return 
    }

    let response
    try {
      loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling getWithoutPrompt()')
      response = await oktaAuth.token.getWithoutPrompt()
    } catch (err) {
      receivedTokenError = true
      console.error(err)
      loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 6 (!isAuthenticated getWithoutPrompt() catch() err.message = ' + (err as Error).message + ')')
      await logout(next)
      return 
    }

    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') setting tokens from response of getWithoutPrompt()')
    const tokens = response.tokens
    await setTokens(tokens)
  } else {
    // If session expired then logout.
    // if (!session || session.status !== 'ACTIVE') {
    //   let errCaught
    //   await oktaAuth.session.refresh()
    //     .then((session) => {
    //       // Existing session is now refreshed.
    //       loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') session.refresh() successful, session id/status = ' + session.id + '/' + session.status + ', session object =', session)
    //       errCaught = false
    //     })
    //     .catch((err) => {
    //       // There was a problem refreshing (the user may not have an existing session).
    //       loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') session.refresh() catch(), err.messsage = ' + (err as Error).message + ', err object =', err)
    //       loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 7 (session expired)')
    //       logout(next)
    //       errCaught = true
    //     })
    //   if (errCaught) {
    //     return
    //   }
    // }
  }

  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling finishLogin()')
  finishLogin(next)
}

/** Goes to next() or 'dashboard', else logout() or originalUri */
export async function finishLogin(next: Function | null): Promise<void> {
  loginLog('finishLogin() checking hintMatchesSessionUser()')
  const hintMatchesSession = await hintMatchesSessionUser()
  if (!hintMatchesSession) {
    loginLog('finishLogin() detected a different user than the logged in one... redirecting..')
    await logout(next)
    return 
  }
   
  loginLog('finishLogin() calling setHttpBearerToken()')
  await setHttpBearerToken()
  receivedTokenError = false

  loginLog('finishLogin() calling checkForOriginalUri()')
  if (checkForOriginalUri(next)) {
    loginLog('finishLogin() returning without calling next()')
    return
  }
  
  if (next) {
    loginLog('finishLogin() calling next()')
    next()
  } else {
    loginLog('finishLogin() routing to \'dashboard\'')
    router.push('dashboard')
  }
}

/** Login according to useOktaLogin, calls loginOkta() or next()/'login' */
function login(next: Function | null): void {
  loginLog('login() next=', next)
  oktaAuthError = false
  const to = window.location.pathname
  setOriginalUri(to)
  if (useOktaLogin) {
    loginOkta()
    if (next) next()
  } else {
    loginLog('login() redirect to route \'login\'')
    if (next) {
      next({ name: 'login' })
    } else {
      router.push({ name: 'login' })
    }
  }
}

/** Calls oktaAuth.token.getWithRedirect() */
export function loginOkta(): void {
  loginLog('loginOkta()')
  const obj = {
    responseType: 'code',
    scopes: ['openid', 'profile', 'email'],
    loginHint: getQueryVariable('login_hint'),
    idp: getQueryVariable('idp')
  }
  loginLog('loginOkta() obj=', obj)
  oktaAuth.token.getWithRedirect(obj)
}

/** Calls oktaAuth.revokeAccessToken() and oktaAuth.closeSession() */
export async function logout(next: Function | null): Promise<void> {
  loggingOut = true
  loginLog('logout()')

  if (!oktaAuthSubscribed) {
    loginLog('logout() calling oktaAuthSubscribe()')
    await oktaAuthSubscribe()
  }
  loginLog('logout() calling revokeAccessToken()')
  await oktaAuth.revokeAccessToken()

  loginLog('logout() calling tokenManager.clear()')
  oktaAuth.tokenManager.clear()

  try {
    loginLog('logout() calling closeSession()')
    await oktaAuth.closeSession()
  } catch (err) {
    // @ts-ignore
    if (err.xhr && err.xhr.status === 429) {
      loginLog('logout() Too many requests', err)
    }
  }

  loggingOut = false
  loginLog('logout() calling login()')
  login(next)
}

const logQueue = []

/** Writes argument value to the console log */
export function loginLog(log: string, arg2?: any): void {
  if (!flagsLoaded) {
    if (logQueue) {
      logQueue.push({ date: new Date(), log, arg2 } as never)
    } else {
      loginLogPerformOutput(new Date(), '(always on) ' + log, arg2)
    }
  } else {
    loginLogPerformOutput(new Date(), log, arg2)
  }
}

function loginLogWriteOutputQueue(): void {
  while (logQueue.length > 0) {
    const logItem: any = logQueue.shift()
    loginLogPerformOutput(logItem.date, logItem.log, logItem.arg2)
  }
}

function loginLogPerformOutput(date, log: string, arg2: any): void {
  const time = date.toString().split(' ')[4] + '.' + date.getMilliseconds().toString().padStart(3, '0')
  if (useLoginLogging) {
    if (arg2) {
      console.warn(time + ' LOGIN LOG: ' + log, arg2)
    } else {
      console.warn(time + ' LOGIN LOG: ' + log)
    }
  }
}

/** Callback from okta login used by the router */
export async function callback(): Promise<void> {
  loginLog('callback()')
  await oktaAuth.token.parseFromUrl()
    .then(({ tokens }) => {
      if (oktaAuth && oktaAuth.authStateManager) {
        loginLog('callback() subscribing to authStateManager')
        oktaAuth.authStateManager.subscribe(authState => {
          // handle latest authState
        })
        loginLog('callback() updating authStateManager')
        oktaAuth.authStateManager.updateAuthState()
      }
  
      loginLog('callback() tokens=', tokens)
      setTokens(tokens)

      loginLog('callback() calling finishLogin()')
      finishLogin(null)
    })
    .catch(err => {
      oktaAuthError = true
      document.write('<div style="font-size: 24px; position: absolute; top: 100px; left: 100px;">' + err.message + '</div>')
      console.error(err)
    })
}

/** Set redirectUri (0=/implicit/callback, 1=/authorization-callback) and create OktaAuth Obj */
export function initOkta(redirectUriIndex): void {
  loginLog('initOkta()')
  if (oktaAuth.options.redirectUri || !redirectUris) {
    return
  }
  authConfig.redirectUri = redirectUris[redirectUriIndex]
  const oktaAuthObj = {
    issuer: authConfig.issuer,
    clientId: authConfig.clientId,
    redirectUri: authConfig.redirectUri
  }
  loginLog('initOkta() creating OktaAuth obj=', oktaAuthObj)
  oktaAuth = new OktaAuth(oktaAuthObj)
}

/** Calls oktaAuth.tokenManager.get('idToken') */
export function getIdToken(): Promise<any> {
  return oktaAuth.tokenManager.get('idToken')
}
  
/** Calls oktaAuth.tokenManager.get('accessToken') */
export function getAccessToken(): Promise<any> | {} {
  return oktaAuth && oktaAuth.tokenManager ? oktaAuth.tokenManager.get('accessToken') : {}
}

/** Calls oktaAuth.token.getUserInfo() */
export async function getUserInfo() : Promise<UserClaims> {
  loginLog('getUserInfo()')
  const userInfo = await oktaAuth.token.getUserInfo()
  return userInfo
}

/** Calls oktaAuth.tokenManager.setTokens(tokens) */
export async function setTokens(tokens: any): Promise<void> {
  loginLog('setTokens() tokens=', tokens)

  if (store.hasOwnProperty('commit')) {
    // @ts-ignore
    store.commit('setOktaTokens', tokens)
  }

  if (!oktaAuthSubscribed) {
    loginLog('setTokens() calling oktaAuthSubscribe()')
    await oktaAuthSubscribe()
  }
  oktaAuth.tokenManager.setTokens(tokens)
}

/** Calls oktaAuth.tokenManager.getTokens() */
export function getTokens(): Promise<any> {
  return oktaAuth.tokenManager.getTokens()
}

/** Calls oktaAuth.isAuthenticated() */
export function isAuthenticated() : Promise<boolean> {
  return oktaAuth.isAuthenticated()
}

/** Turn on/off using okta login */
export function setUseOktaLogin(useOktaLoginValue: boolean): void {
  useOktaLogin = useOktaLoginValue
}

/** Returns whether using okta login or not */
export function getUseOktaLogin(): boolean {
  return useOktaLogin
}

/** Sets redirectUri value */
export function setRedirectUri(redirectUriValue: string): void {
  authConfig.redirectUri = redirectUriValue
}

/** Returns redirectUri value */
export function getRedirectUri(): string {
  return authConfig.redirectUri
}

/** Returns the received token error and resets it to false */
export function getReceivedTokenErrorAndReset(): boolean {
  const prevTokenError = receivedTokenError
  receivedTokenError = false
  return prevTokenError
}

/** Waits for authentication to complete, if user is not authenticated it will timeout/logout */
export function waitForAuthentication(): Promise<any> {
  loginLog('waitForAuthentication()')
  const WAIT_TIME_IN_MILLIS = 300
  const MAX_TIME_IN_SECONDS = 60
  let retryCount = 0
  return new Promise(async (resolve: Function, reject: Function) => {
    try {
      (async function waitForAuthenticationTimer() {
        if ((retryCount++ * WAIT_TIME_IN_MILLIS / 1000) > MAX_TIME_IN_SECONDS) {
          loginLog('waitForAuthentication() User not authenticated - max time exceeded ' + MAX_TIME_IN_SECONDS + ' seconds')
          await logout(null)
          resolve()
        } else {
          const isAuthenticatedValue = await isAuthenticated()
          if (loggingOut || oktaAuthError) {
            reject(new Error('loggingOut=' + loggingOut + ', oktaAuthError=' + oktaAuthError))
          } else if (!isAuthenticatedValue) {
            loginLog('waitForAuthentication() User not authenticated - retrying...')
            setTimeout(waitForAuthenticationTimer, WAIT_TIME_IN_MILLIS)
          } else {
            loginLog('waitForAuthentication() User authenticated - continuing')
            resolve()
          }
        }
      })()
    } catch (err) {
      console.error(err)
      loginLog('waitForAuthentication() waitForAuthenticationTimer() catch() err.message = ' + (err as Error).message)
      reject(err)
    }
  })
}