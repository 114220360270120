// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import confirmationTypes from './confirmationTypes'
import JQuery from 'jquery'

export default defineComponent({
  name: 'confirmation',
  data() {
    return {
      affirmativeFunction: {} as Function,
      confirmationType: null as confirmationTypes
    }
  },
  mounted() {
    JQuery('#confirmation').on('show.bs.modal', this.showModal)
  },
  computed: {
    affirmativeButtonText(): string {
      switch (this.confirmationType) {
        case confirmationTypes.UNSAVED_CHANGES:
          return this.$t('return_button')
        case confirmationTypes.DELETE:
        case confirmationTypes.DELETE_MULTIPLE:
          return this.$t('delete_button')
        default:
          return this.$t('ok_button')
      }
    },
    negativeButtonText() {
      switch (this.confirmationType) {
        case confirmationTypes.UNSAVED_CHANGES:
          return this.$t('stay_button')
        default:
          return this.$t('cancel_button')
      }
    },
    primaryMessage(): string {
      switch (this.confirmationType) {
        case confirmationTypes.UNSAVED_CHANGES:
          return this.$t('reseller_unsaved-changes-modal_message')
        case confirmationTypes.DELETE_MULTIPLE:
          return this.$t('delete-warning-modal_multiple-delete_text')
        default:
          return this.$t('delete-warning-modal_single-delete_text')
      }
    },
    secondaryMessage(): string {
      switch (this.confirmationType) {
        case confirmationTypes.UNSAVED_CHANGES:
          return this.$t('configurator_unsaved-changes-modal_text')
        default:
          return this.$t('delete-warning-modal_text')
      }
    },
    title(): string {
      switch (this.confirmationType) {
        case confirmationTypes.UNSAVED_CHANGES:
          return this.$t('reseller_unsaved-changes-modal_title')
        default:
          return this.$t('delete-warning-modal_title')
      }
    }
  },
  methods: {
    affirmativeAction() {
      this.affirmativeFunction()
      JQuery('#confirmation').modal('hide')
    },
    showModal() {
      this.confirmationType = JQuery('#confirmation').data('bs-confirmation-type')
      this.affirmativeFunction = JQuery('#confirmation').data('bs-affirmative-function') as Function
    }
  }
})