// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Localization from '@/utils/localization'
import { Nullable } from '@/_typings/types/nullable'
import JQuery from 'jquery'
import { getFlagsmithEnvID } from '@/flagsmithCredentials'

export default defineComponent({
  name: 'main-header',
  Localization,
  data() {
    return {
      logoUrl: undefined as Nullable<string> | undefined,
    }
  },
  created() {
    this.fetchLogo().then(data => {
      this.logoUrl = data.logoUrl
    })
  },
  updated() {
    JQuery(function () {
      JQuery('[data-toggle="tooltip"]').tooltip({ placement: 'top', customClass: 'tooltip', trigger: 'hover', delay: { show: 800, hide: 100 } })
    })
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }), 
    helpURL() {
      if (getFlagsmithEnvID(window.location.hostname, 'reseller') === 'nUrwHvtuUtKL8Pap5GTtLA') {
        return '' //TODO Add Production URL for Help here
      } else {
        return 'https://reseller-help.devkube.eastus2-dev.cincomcloud.com/'
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchLogo'
    ]),
    launchHelp() {
      window.open(this.helpURL, '_blank')
    }
  }
})