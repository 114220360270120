// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { AlertType } from '@/_typings/enums/alertType'

export default defineComponent({
  name: 'alert',
  alertType: AlertType,
  props: {
    message: { type: String, default: null },
    title: { type: String, default: null },
    timeout: { type: Number, default: null },
    type: { type: String as PropType<AlertType>, default: AlertType.Info, validator (val: AlertType) { return [ AlertType.Danger, AlertType.Info, AlertType.Success, AlertType.Warning].includes(val) } }
  }
})