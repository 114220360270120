// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
const esc = encodeURIComponent
function qs(params) : string {
  return Object.keys(params)
    .filter(p => params[p] !== undefined)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&')
}

export { qs, esc }
