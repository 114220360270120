// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { AxiosInstance } from 'axios'
import { logout } from '@/auth'

export function setupInterceptors(httpClient: AxiosInstance) {
  const interceptor = {
    pending: () => {
    },
    done: () => {
    }
  }
  httpClient.interceptors.request.use(config => {
    interceptor.pending()
    return config
  }, error => {
    interceptor.done()
    return Promise.reject(error)
  })
  httpClient.interceptors.response.use(response => {
    interceptor.done()
    return Promise.resolve(response)
  }, error => {
    interceptor.done()
    if (/status code 401/i.test(error.message)) {
      console.error(error.message + ', calling logout()')
      logout(null)
    }
    return Promise.reject(error)
  })
}