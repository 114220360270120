// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
const state: any = {
  runtimeSessionDict: {} as any
}

const getters = {
  getRuntimeSessionId(state) {
    return (key) => {
      return state.runtimeSessionDict[key] ? state.runtimeSessionDict[key] : null
    }
  }
}

const mutations = {
  setRuntimeSessionId(state, { key, value }) {
    state.runtimeSessionDict[key] = value
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}