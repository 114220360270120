// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import portalService from '@/api/portalService'
import { getPagedQueryString } from '@/utils/getPagedQueryString'
import { IPageInfo } from '@/_typings/interfaces/paging'
import handleError from '@/store/handleError'

const state= {
}

const getters = {
}

const mutations = {
}

const actions = {
  addOpportunity({ getters }, { opportunity }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/opportunities`, opportunity)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addOpportunityCatalogItems({ getters }, { opportunityId, catalogItems }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/catalog/items`, catalogItems)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteOpportunities({ getters }, { opportunityIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/opportunities/delete-many`, opportunityIds)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteOpportunity({ getters }, { opportunityId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteOpportunityLine({ getters }, { opportunityId, lineId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/lines/${lineId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteOpportunityLines({ getters }, { opportunityId, lineIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/lines/delete-many`, lineIds)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchOpportunities({ getters }, { pageInfo = {}, quoteId = null, activeOnly = false }) {
    let query = getPagedQueryString(pageInfo as IPageInfo)
    if (quoteId) query = query + (query.length ? '&' : '') + 'quoteId=' + quoteId
    else if (activeOnly) query = query + (query.length ? '&' : '') + 'activeOnly=' + activeOnly.toString()
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/opportunities?${query}`)
        .then(({ data }) => {
          resolve({ ...data, items: data.items.map(item => { return { ...item, accountName: item.account ? item.account.name : null, ownerName: item.owner ? item.owner.fullName : null } }) })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchOpportunity({ getters }, { opportunityId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}`)
        .then(({ data }) => {
          resolve({ ...data, quotes: { ...data.quotes, items: data.quotes.items.map(item => { return { ...item, ownerName: item.owner ? item.owner.fullName : null } }) } })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchOpportunityCatalog({ getters }, { opportunityId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/catalog?${query}`, [])
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchOpportunityLines({ getters }, { opportunityId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/lines?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchOpportunityQuotes({ getters }, { opportunityId, pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/quotes?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchOpportunityStatuses({ getters }, { opportunityId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/statuses`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  reassignOpportunities({ getters }, { ids, ownerId }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/opportunities/owners`, { ids, ownerId })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  receiveOpportunity({ getters }, { opportunityId }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/received`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateOpportunity({ getters }, { opportunity }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/opportunities/${opportunity.id}`, opportunity)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateOpportunityStatus({ getters }, { opportunityId, opportunityStatus }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/opportunities/${opportunityId}/actions/${opportunityStatus.action}`, { reason: opportunityStatus.reason })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}