// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import portalService from '@/api/portalService'
import { getPagedQueryString } from '@/utils/getPagedQueryString'
import { IPageInfo } from '@/_typings/interfaces/paging'
import handleError from '@/store/handleError'

const state= {
}

const getters = {
}

const mutations = {
}

const actions = {
  addContact({ getters }, { contact }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/contacts`, contact)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteContact({ getters }, { contactId }) {
    return new Promise((resolve, reject) => {
      portalService.delete(`tenants/${getters.getUserTenantId}/contacts/${contactId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteContacts({ getters }, { contactIds }) {
    return new Promise((resolve, reject) => {
      portalService.post(`tenants/${getters.getUserTenantId}/contacts/delete-many`, contactIds)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchContact({ getters }, { contactId }) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/contacts/${contactId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchContacts({ getters }, { pageInfo = {}, opportunityId = null, quoteId = null, accountId = null, activeOnly = false }) {
    let query = getPagedQueryString(pageInfo as IPageInfo)
    if (opportunityId) query = query + (query.length ? '&' : '') + 'opportunityId=' + opportunityId
    else if (quoteId) query = query + (query.length ? '&' : '') + 'quoteId=' + quoteId
    else if (activeOnly) query = query + (query.length ? '&' : '') + 'activeOnly=' + activeOnly.toString()
    if (accountId) query = query + (query.length ? '&' : '') + 'accountId=' + accountId
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/contacts?${query}`)
        .then(({ data }) => {
          resolve({ ...data, items: data.items.map(item => { return { ...item, accountName: item.account ? item.account.name : null } }) })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateContact({ getters }, { contact }) {
    return new Promise((resolve, reject) => {
      portalService.put(`tenants/${getters.getUserTenantId}/contacts/${contact.id}`, contact)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}