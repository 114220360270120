// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
import { createStore } from 'vuex'
import account from './modules/account.store'
import configurator from './modules/configurator.store'
import contact from './modules/contact.store'
import opportunity from './modules/opportunity.store'
import quote from './modules/quote.store'
import salesOrder from './modules/sales-order.store'
import task from './modules/task.store'
import tenant from './modules/tenant.store'
import user from './modules/user.store'
import portalService from '@/api/portalService'
import handleError from '@/store/handleError'
import { IBreadcrumb } from '@/_typings/interfaces/breadcrumb'

export default createStore({
  state: {
    alert: null,
    breadcrumbDict: {} as IBreadcrumb,
    featureFlags: { allFlags: {}, params: {} },
    isChangeProcessing: false,
    isReadyI18n: false,
  },
  getters: {
    getAlert(state) {
      return state.alert
    },
    getBreadcrumbText(state) {
      return (id) => {
        return state.breadcrumbDict[id] ? state.breadcrumbDict[id] : null
      }
    },
    getFeatureFlags(state) {
      return state.featureFlags ? state.featureFlags.allFlags : {}
    },
    getIsChangeProcessing(state) {
      return state.isChangeProcessing
    },
    getIsReadyI18n(state) {
      return state.isReadyI18n
    }
  },
  mutations: {
    setAlert(state, alert) {
      state.alert = alert
    },
    setBreadcrumbText(state, breadcrumb) {
      state.breadcrumbDict[breadcrumb.id] = breadcrumb.text
    },
    setFeatureFlags(state, featureFlags) {
      return state.featureFlags = featureFlags
    },
    setIsChangeProcessing(state, isChangeProcessing) {
      state.isChangeProcessing = isChangeProcessing
    },
    setIsReadyI18n(state, isReadyI18n) {
      return state.isReadyI18n = isReadyI18n
    }
  },
  actions: {
    fetchEntityName({ getters }, { entityName, entityId }) {
      return new Promise((resolve, reject) => {
        portalService.get(`tenants/${getters.getUserTenantId}/${entityName}/${entityId}/name`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => {
            handleError(error)
            reject(error)
          })
      })
    }  
  },
  modules: {
    account,
    configurator,
    contact,
    opportunity,
    quote,
    salesOrder,
    task,
    tenant,
    user
  }
})
