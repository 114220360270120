// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
import { SIGNALR_CONNECTION_STATE_OPEN, SIGNALR_CONNECTION_STATE_CLOSED, SIGNALR_CONNECTION_STATE_FAILED } from '@/store/signalRConnectionStates'
import store from '@/store'
import { getAccessToken as getAccessTokenOkta, waitForAuthentication } from '@/auth'

const BASE_URL = window.KUBE_SETTINGS.TaskServiceURL
let stopWasCalled = false

const getAccessToken = async (count) => {
  let retryCount = count !== undefined ? count + 1 : 0
  return new Promise((resolve, reject) => {
    getAccessTokenOkta()
      .then(data => {
        if (data === undefined) {
          setTimeout(() => {
            if (retryCount > 10) {
              console.log('failed to get access token 10 times.. logging out.')
              // Vue.prototype.$auth.signOut()
              // Vue.prototype.$auth.signInWithRedirect('/')
            }
            resolve(getAccessToken(retryCount))
          }, 500)
        } else {
          resolve(data.accessToken)
        }
      })
      .catch((error) => {
        console.error('Error getting access token', error)
        reject(error)
      })
  })
}

const taskHub = new HubConnectionBuilder()
  .withUrl(`${BASE_URL}/taskHub`, {
    transport: HttpTransportType.WebSockets,
    skipNegotiation: true,
    accessTokenFactory: () => getAccessToken()
  })
  .withAutomaticReconnect()
  .build()

taskHub.onclose(async () => {
  store.commit('setSignalRConnectionState', SIGNALR_CONNECTION_STATE_CLOSED)
  if (!stopWasCalled) {
    await start()
  }
})

let signalRRetryCount = 0

const start = async () => {
  await waitForAuthentication()
  return new Promise(async (resolve, reject) => {
    try {
      await taskHub.start()
      stopWasCalled = false
      store.commit('setSignalRConnectionState', SIGNALR_CONNECTION_STATE_OPEN)
      console.info('SignalR started...')
      resolve()
    } catch (error) {
      signalRRetryCount++
      console.error('Error starting SignalR Connection', signalRRetryCount)
      if (signalRRetryCount < 5) {
        setTimeout(() => {
          start()
        }, 2000) 
      } else {
        store.commit('setSignalRConnectionState', SIGNALR_CONNECTION_STATE_FAILED)
        console.error('SignalR Connection retry count exceeded', error)
        reject(new Error('Error starting SignalR Connection'))
      }
    }
  })
}

const stop = async () => {
  taskHub.stop()
  stopWasCalled = true
}

export default {
  taskHub,
  start,
  stop
}
