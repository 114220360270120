// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
import portalService from '@/api/portalService'
import { getPagedQueryString } from '@/utils/getPagedQueryString'
import { IPageInfo } from '@/_typings/interfaces/paging'
import handleError from '@/store/handleError'

const state: any = {
  oktaTokens: null as any,
  user: null as any
}

const getters = {
  getOktaTokens(state) {
    return state.oktaTokens
  },
  getUser(state) {
    return state.user
    // const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage') || '{}')
    // return oktaToken && oktaToken.idToken && oktaToken.idToken.claims
    //   ? oktaToken.idToken.claims
    //   : state.user ? state.user : null
  },
  getUserTenantId(state) {
    const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage') || '{}')
    return oktaToken && oktaToken.idToken && oktaToken.idToken.claims
      ? oktaToken.idToken.claims.tenant
      : state.user && state.user.tenant ? state.user.tenant : null

    // return state.oktaTokens && state.oktaTokens.idToken && state.oktaTokens.idToken.claims
    //   ? state.oktaTokens.idToken.claims.tenant
    //   : state.user && state.user.tenant ? state.user.tenant : null
  }
}

const mutations = {
  setOktaTokens(state, oktaTokens) {
    state.oktaTokens = oktaTokens
  },
  setUser(state, user) {
    state.user = user
  }
}

const actions = {
  fetchLoggedOnUser({ getters } ) {
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/users/me`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchUsers({ getters }, { pageInfo = {} }) {
    const query = getPagedQueryString(pageInfo as IPageInfo)
    return new Promise((resolve, reject) => {
      portalService.get(`tenants/${getters.getUserTenantId}/users?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}