import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { callback, validateAccessAsync, logout, initOkta, setUseOktaLogin } from '@/auth'

const routes: Array<RouteRecordRaw> = [
  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../pages/dashboard/index.vue'),
  },
  // account
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import(/* webpackChunkName: "account" */ '../pages/account/accounts/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'accounts',
        locizeKey: 'reseller_accounts_breadcrumb'
      }]
    }
  },
  {
    path: '/account/',
    name: 'account-new',
    component: () => import(/* webpackChunkName: "account" */ '../pages/account/account/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'accounts',
        locizeKey: 'reseller_accounts_breadcrumb'
      },
      {
        name: 'account-new',
        locizeKey: 'reseller_account-add_breadcrumb'
      }]
    }
  },
  {
    path: '/account/:accountId',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../pages/account/account/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'accounts',
        locizeKey: 'reseller_accounts_breadcrumb'
      },
      {
        name: 'account',
        paramId: 'accountId'
      }]
    }
  },
  // contact
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contact" */ '../pages/contact/contacts/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'contacts',
        locizeKey: 'reseller_contacts_breadcrumb'
      }]
    }
  },
  {
    path: '/contact/',
    name: 'contact-new',
    component: () => import(/* webpackChunkName: "contact" */ '../pages/contact/contact/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'contacts',
        locizeKey: 'reseller_contacts_breadcrumb'
      },
      {
        name: 'contact-new',
        locizeKey: 'reseller_contact-add_breadcrumb'
      }]
    }
  },
  {
    path: '/contact/:contactId',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../pages/contact/contact/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'contacts',
        locizeKey: 'reseller_contacts_breadcrumb'
      },
      {
        name: 'contact',
        paramId: 'contactId'
      }]
    }
  },
  // opportunity
  {
    path: '/opportunities',
    name: 'opportunities',
    component: () => import(/* webpackChunkName: "opportunity" */ '../pages/opportunity/opportunities/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      }]
    }
  },
  {
    path: '/opportunity',
    name: 'opportunity-new',
    component: () => import(/* webpackChunkName: "opportunity" */ '../pages/opportunity/opportunity/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity-new',
        locizeKey: 'reseller_opportunity-add_breadcrumb'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId',
    name: 'opportunity',
    component: () => import(/* webpackChunkName: "opportunity" */ '../pages/opportunity/opportunity/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId/product-catalog',
    name: 'opportunity-product-catalog',
    component: () => import(/* webpackChunkName: "product-catalog" */ '../pages/product-catalog/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      },
      {
        name: 'opportunity-product-catalog',
        locizeKey: 'reseller_product-catalog_breadcrumb'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId/configurator',
    name: 'opportunity-configurator',
    component: () => import(/* webpackChunkName: "configurator" */ '../pages/configurator/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      },
      {
        name: 'opportunity-configurator',
        locizeKey: 'reseller_configurator_breadcrumb'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId/quote',
    name: 'opportunity-quote-new',
    component: () => import(/* webpackChunkName: "quote" */ '../pages/quote/quote/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      },
      {
        name: 'opportunity-quote-new',
        locizeKey: 'reseller_quote-add_breadcrumb'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId/quote/:quoteId',
    name: 'opportunity-quote',
    component: () => import(/* webpackChunkName: "quote" */ '../pages/quote/quote/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      },
      {
        name: 'opportunity-quote',
        paramId: 'quoteId'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId/quote/:quoteId/product-catalog',
    name: 'opportunity-quote-product-catalog',
    component: () => import(/* webpackChunkName: "product-catalog" */ '../pages/product-catalog/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      },
      {
        name: 'opportunity-quote',
        paramId: 'quoteId'
      },
      {
        name: 'opportunity-quote-product-catalog',
        locizeKey: 'reseller_product-catalog_breadcrumb'
      }]
    }
  },
  {
    path: '/opportunity/:opportunityId/quote/:quoteId/configurator',
    name: 'opportunity-quote-configurator',
    component: () => import(/* webpackChunkName: "configurator" */ '../pages/configurator/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'opportunities',
        locizeKey: 'reseller_opportunities_breadcrumb'
      },
      {
        name: 'opportunity',
        paramId: 'opportunityId'
      },
      {
        name: 'opportunity-quote',
        paramId: 'quoteId'
      },
      {
        name: 'quote-configurator',
        locizeKey: 'reseller_configurator_breadcrumb'
      }]
    }
  },
  // quote
  {
    path: '/quotes',
    name: 'quotes',
    component: () => import(/* webpackChunkName: "quote" */ '../pages/quote/quotes/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'quotes',
        locizeKey: 'reseller_quotes_breadcrumb'
      }]
    }
  },
  {
    path: '/quote/',
    name: 'quote-new',
    component: () => import(/* webpackChunkName: "quote" */ '../pages/quote/quote/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'quotes',
        locizeKey: 'reseller_quotes_breadcrumb'
      },
      {
        name: 'quote-new',
        locizeKey: 'reseller_quote-add_breadcrumb'
      }]
    }
  },
  {
    path: '/quote/:quoteId',
    name: 'quote',
    component: () => import(/* webpackChunkName: "quote" */ '../pages/quote/quote/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'quotes',
        locizeKey: 'reseller_quotes_breadcrumb'
      },
      {
        name: 'quote',
        paramId: 'quoteId'
      }]
    }
  },
  {
    path: '/quote/:quoteId/product-catalog',
    name: 'quote-product-catalog',
    component: () => import(/* webpackChunkName: "product-catalog" */ '../pages/product-catalog/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'quotes',
        locizeKey: 'reseller_quotes_breadcrumb'
      },
      {
        name: 'quote',
        paramId: 'quoteId'
      },
      {
        name: 'quote-product-catalog',
        locizeKey: 'reseller_product-catalog_breadcrumb'
      }]
    }
  },
  {
    path: '/quote/:quoteId/configurator',
    name: 'quote-configurator',
    component: () => import(/* webpackChunkName: "configurator" */ '../pages/configurator/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'quotes',
        locizeKey: 'reseller_quotes_breadcrumb'
      },
      {
        name: 'quote',
        paramId: 'quoteId'
      },
      {
        name: 'quote-configurator',
        locizeKey: 'reseller_configurator_breadcrumb'
      }]
    }
  },
  // sales-order
  {
    path: '/sales-orders',
    name: 'sales-orders',
    component: () => import(/* webpackChunkName: "sales-order" */ '../pages/sales-order/sales-orders/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'sales-orders',
        locizeKey: 'reseller_sales-orders_breadcrumb'
      }]
    }
  },
  {
    path: '/sales-order/:salesOrderId',
    name: 'sales-order',
    component: () => import(/* webpackChunkName: "sales-order" */ '../pages/sales-order/sales-order/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'sales-orders',
        locizeKey: 'reseller_sales-orders_breadcrumb'
      },
      {
        name: 'sales-order',
        paramId: 'salesOrderId'
      }]
    }
  },
  {
    path: '/sales-order/:salesOrderId/configurator',
    name: 'sales-order-configurator',
    component: () => import(/* webpackChunkName: "configurator" */ '../pages/configurator/index.vue'),
    meta: {
      breadcrumbs: [{
        name: 'sales-orders',
        locizeKey: 'reseller_sales-orders_breadcrumb'
      },
      {
        name: 'sales-order',
        paramId: 'salesOrderId'
      },
      {
        name: 'sales-order-configurator',
        locizeKey: 'reseller_configurator_breadcrumb'
      }]
    }
  },
  // miscellaneous
  {
    path: '/refresh',
    redirect: to => {
      return { path: '/' + to.query.name + '/' + to.query.id + (to.query.name2 && to.query.id2 ? '/' + to.query.name2 + '/' + to.query.id2 : '') }
    }
  },
  {
    path: '/login/callback',
    name: 'LoginCallback',
    component: () => import(/* webpackChunkName: "login-callback" */ '../components/misc/login/LoginCallback.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/misc/login/login/index.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'logout',
    path: '/logout',
    component: logout
  },
  {
    name: 'callback',
    path: '/authorization-callback',
    component: callback
  },
  {
    path: '/:catchAll(.*)',
    redirect: {
      name: 'dashboard'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// @ts-ignore
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  // Inside beforeEach() since fails outside due to auth.ts not loaded
  setUseOktaLogin(false)
  initOkta(1) 

  await validateAccessAsync(to, from, next)
  return 
})

export default router
